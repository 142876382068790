import React from 'react'
import {Link} from 'react-router-dom'
import {Box, Typography} from '@mui/material'

const NotFound = () => {
    return <Box
        sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <Typography variant={'h1'}>Error 404</Typography>
        <Typography variant={'body1'}>Sorry, page not found. Go to <Link to={'/'}>main page</Link>.</Typography>
    </Box>
}

export default NotFound
