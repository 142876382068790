import React, {useEffect, useState} from 'react'
import {NavLink, Outlet, useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {SignedIn, SignedOut, SignOutButton, useAuth, useUser} from '@clerk/clerk-react'
import {
    AppBar,
    Badge,
    Box,
    Container,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography
} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import LogoutIcon from '@mui/icons-material/Logout'
import {Redirect, Request} from '../system'
import {Copyright} from '../static'
import {AppDispatch} from '../../store/store'
import {requestAuth} from '../../store/appSlice'

const OfficeLayout = () => {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const [sessionToken, setSessionToken] = useState<string | null>(null)
    const { isSignedIn, user, isLoaded } = useUser()
    console.log(user)
    const {getToken, sessionId} = useAuth()
    getToken().then(v => {setSessionToken(v)})
    console.log(sessionId)


    useEffect(() => {
        if (sessionToken) {
            dispatch(requestAuth({sessionId: sessionId || '', sessionToken}))
        }
    }, [sessionToken])

    return <Box sx={{display: 'flex'}}>
        <CssBaseline/>
        <SignedOut>
            <Redirect to={'/'}/>
        </SignedOut>
        <SignedIn>
            <Request/>
            <AppBar position="absolute">
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        Dashboard
                    </Typography>
                    <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon/>
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent">
                <Divider/>
                <List component="nav">
                    <ListItemButton component={NavLink} to={'/office'}>
                        <ListItemIcon>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Dashboard"/>
                    </ListItemButton>
                    <ListItemButton component={NavLink} to={'/office/invoices'}>
                        <ListItemIcon>
                            <ShoppingCartIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Invoices"/>
                    </ListItemButton>
                    <Divider sx={{my: 1}}/>
                    <SignOutButton signOutCallback={() => {
                        navigate('/')
                    }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Exit"/>
                        </ListItemButton>
                    </SignOutButton>
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar/>
                <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                    <Outlet/>
                    <Copyright/>
                </Container>
            </Box>
        </SignedIn>
    </Box>
}

export default OfficeLayout
