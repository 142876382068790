import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import {RootState} from './store'
import {API_URL} from "../utils/constants";

interface AppState {
    loaded: boolean
    redirectPath: string | null
}

const initialState: AppState = {
    loaded: false,
    redirectPath: null,
}

/*
export const checkResponse = createAsyncThunk(
    'app/checkResponse',
    async (response: SliceResponse, {dispatch}): Promise<void> => {
        response.beforeCheckCallback?.()
        switch (response.status) {
            case 200:
                response.successCallback?.()
                break
            case 401:
                dispatch(setAuthError(true))
                break
            default:
                response.error = {title: 'Error', text: response.error?.text || 'Some error'}
        }
        let data = response.data
        if (response.error) {
            if (response.status !== 401) {
                dispatch(setModalError({...response.error, buttons: ['close']}))
            }
            data = response.defaultData || null
        }
        response.setData?.(data)
        response.afterCheckCallback?.()
    }
)
*/
export const initialize = createAsyncThunk(
    'app/initialize',
    async (_, {dispatch}): Promise<boolean> => {
/*
        dispatch(setWalletAddress(getFromStorage('walletAddress')))
        dispatch(setJwt(getFromWalletStorage('jwt')))
        dispatch(setSelectedOrganizerId(Number(getFromWalletStorage('organizerId'))))
        dispatch(setSelectedEventId(Number(getFromWalletStorage('eventId'))))
*/
        return true
    }
)
export const requestAuth = createAsyncThunk(
    'app/requestAuth',
    async ({sessionId, sessionToken}: {sessionId: string, sessionToken: string}, {dispatch}): Promise<boolean> => {
        console.log(await axios.get(`${API_URL}protected-route`, {headers: {Authorization: `Bearer: ${sessionToken}`}}))
/*
        dispatch(setWalletAddress(getFromStorage('walletAddress')))
        dispatch(setJwt(getFromWalletStorage('jwt')))
        dispatch(setSelectedOrganizerId(Number(getFromWalletStorage('organizerId'))))
        dispatch(setSelectedEventId(Number(getFromWalletStorage('eventId'))))
*/
        return true
    }
)
/*
export const initializeNetwork = createAsyncThunk(
    'app/initializeNetwork',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork} = state.app

        dispatch(setSelectedMintCollection(getFromNetworkStorage('selectedMintCollection', currentNetwork)))
        dispatch(setSelectedSbtCollection(getFromNetworkStorage('selectedSbtCollection', currentNetwork)))
    }
)
export const loadExternalUrl = createAsyncThunk(
    'app/loadExternalUrl',
    async (url: string): Promise<LoadingExternalUrlType | null> => {
        if (url === '') {
            return null
        }
        const res = await fetch(url)
        if (res.status === 200) {
            const contentType = res.headers.get('Content-Type') || undefined
            const data = await res.arrayBuffer()
            const blob = new Blob([data])
            const file = new File([blob], 'filename', {type: contentType})
            return {file}
        } else {
            console.log(`Loading error with status ${res.status}`)
            return {error: res.statusText}
        }
    }
)
export const networkChanged = createAsyncThunk(
    'app/networkChanged',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app

        dispatch(setNames({}))
        if (walletAddress) {
            dispatch(setMintCollections(null))
            dispatch(setSbtCollections(null))
            dispatch(setSelectedMintCollection(getFromNetworkStorage('selectedMintCollection', currentNetwork)))
            dispatch(setSelectedSbtCollection(getFromNetworkStorage('selectedSbtCollection', currentNetwork)))
        }
    }
)
export const sendTransaction = createAsyncThunk(
    'app/sendTransaction',
    async (index: number, {dispatch, getState}): Promise<boolean> => {
        const state = getState() as RootState
        const {modalSendTransactions, signer} = state.app

        if (!signer || !modalSendTransactions || index >= modalSendTransactions.length) {
            return false
        }

        const showError = (title: string, text: string | string[]) => {
            dispatch(setModalError({title, text, buttons: ['close']}))
        }

        try {
            console.log(`sending transaction:`)
            console.log(modalSendTransactions[index].trx)
            const tx = await signer.sendTransaction(modalSendTransactions[index].trx)
            console.log(tx)
            dispatch(setModalSendTransactionStatus({index, status: true}))
            modalSendTransactions[index].afterSigningCallback?.()

            try {
                const receipt = await tx.wait()
                if (receipt && receipt.status === 1) {
                    console.log(receipt)
                    dispatch(setModalSendTransactionTrxId({index, trxId: receipt.transactionHash}))
                    modalSendTransactions[index].successfulSendingCallback?.()
                }
                return true
            } catch (error: any) {
                console.log(error.receipt)
                showError('Transaction error', 'Failed transaction')
                return false
            }
        } catch (e: any) {
            const error: string[] = []
            error.push(e.message || e.toString())
            if (e.data && e.data.message) {
                error.push(e.data.message)
            }
            console.log(e)
            showError('Signing transaction error', error)
            return false
        }
    }
)
export const walletChanged = createAsyncThunk(
    'app/walletChanged',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app

        if (!walletAddress) {
            return
        }

        dispatch(closeAllModals())
        dispatch(setTicketLevels(null))
        dispatch(setEventsByOrganizer(null))
        dispatch(setOrganizers(null))
        dispatch(setUser(null))
        const jwt = getFromWalletStorage('jwt')
        dispatch(setJwt(jwt))
        dispatch(requestAuth({account: walletAddress, jwt}))
        dispatch(setSelectedOrganizerId(Number(getFromWalletStorage('organizerId'))))
        dispatch(setSelectedEventId(Number(getFromWalletStorage('eventId'))))
        dispatch(setTicketLevelId(null))
        dispatch(setMintCollections(null))
        dispatch(setSbtCollections(null))
        dispatch(setSelectedMintCollection(getFromNetworkStorage('selectedMintCollection', currentNetwork)))
        dispatch(setSelectedSbtCollection(getFromNetworkStorage('selectedSbtCollection', currentNetwork)))
        dispatch(setOnChainEvents(null))
    }
)

const revokeImage = (image: string) => {
    if (image) {
        URL.revokeObjectURL(image)
    }
}
*/

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setRedirectPath: (state, action: PayloadAction<string | null>) => {
            state.redirectPath = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initialize.fulfilled, (state, action: PayloadAction<boolean>) => {
            state.loaded = action.payload
        })
    },
})

export const getLoaded = (state: RootState): boolean => state.app.loaded
export const getRedirectPath = (state: RootState): string | null => state.app.redirectPath

export const {
    setRedirectPath
} = appSlice.actions

export default appSlice.reducer
