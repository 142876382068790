import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import {ClerkProvider} from '@clerk/clerk-react'
import {createTheme, ThemeProvider} from '@mui/material'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {CLERK_PUBLISHABLE_KEY} from './utils/constants'
import store from './store/store'

const defaultTheme = createTheme()

if (CLERK_PUBLISHABLE_KEY === '') {
    throw new Error('Missing Publishable Key')
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(<Provider store={store}>
        <BrowserRouter>
            <ThemeProvider theme={defaultTheme}>
                <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
                    <App/>
                </ClerkProvider>
            </ThemeProvider>
        </BrowserRouter>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
