import React from 'react'
import {Outlet} from 'react-router-dom'
import {Container, CssBaseline} from '@mui/material'

const DefaultLayout = () => {
    return <Container component="main">
        <CssBaseline/>
        <Outlet/>
    </Container>
}

export default DefaultLayout
