import React from 'react'
import {NavLink} from 'react-router-dom'
import {SignedIn, SignedOut, SignInButton} from '@clerk/clerk-react'
import {
    AppBar,
    Box,
    Button,
    Card, CardActions,
    CardContent,
    CardHeader,
    Container,
    Grid,
    Toolbar,
    Typography
} from '@mui/material'
import StarIcon from '@mui/icons-material/StarBorder'
import {Copyright} from './index'

const tiers = [
    {
        title: 'Free',
        price: '0',
        description: [
            '10 invoices included',
            '1 employee',
            'Email support',
        ],
        buttonText: 'Sign up for free',
        buttonVariant: 'contained',
    },
    {
        title: 'Pro',
        subheader: 'Most popular',
        price: '39',
        description: [
            '20 invoices included',
            '1 employee',
            'Help center access',
            'Priority email support',
        ],
        buttonText: 'Comming soon...',
        buttonVariant: 'outlined',
    },
    {
        title: 'Enterprise',
        price: '299+',
        description: [
            'Unlimited invoices',
            '1+ employee',
            'Help center access',
            'Phone & email support',
        ],
        buttonText: 'Comming soon...',
        buttonVariant: 'outlined',
    },
]

const About = () => {
    return <>
        <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{borderBottom: (theme) => `1px solid ${theme.palette.divider}`}}
        >
            <Toolbar sx={{flexWrap: 'wrap'}}>
                <Typography variant="h6" color="inherit" noWrap sx={{flexGrow: 1}}>Company name</Typography>
                {/*
                <nav>
                    <Link variant="button" color="text.primary" href="#" sx={{my: 1, mx: 1.5}}>Features</Link>
                    <Link variant="button" color="text.primary" href="#" sx={{my: 1, mx: 1.5}}>Enterprise</Link>
                    <Link variant="button" color="text.primary" href="#" sx={{my: 1, mx: 1.5}}>Support</Link>
                </nav>
*/}
                <SignedOut>
                    <SignInButton afterSignInUrl={'/office'} afterSignUpUrl={'/office'} mode={'modal'}>
                        <Button variant={'contained'} sx={{my: 1, mx: 1.5}}>Sign in</Button>
                    </SignInButton>
                </SignedOut>
                <SignedIn>
                    <Button component={NavLink} to={'/office'} variant={'contained'}>
                        To App
                    </Button>
                </SignedIn>
            </Toolbar>
        </AppBar>
        <Container disableGutters maxWidth="sm" component="main" sx={{pt: 8, pb: 6}}>
            <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
                Pricing
            </Typography>
        </Container>
        <Container maxWidth="md" component="main">
            <Grid container spacing={5} alignItems="flex-end">
                {tiers.map((tier) => (
                    <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                        <Card>
                            <CardHeader
                                title={tier.title}
                                subheader={tier.subheader}
                                titleTypographyProps={{align: 'center'}}
                                action={tier.title === 'Pro' ? <StarIcon/> : null}
                                subheaderTypographyProps={{
                                    align: 'center',
                                }}
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'light'
                                            ? theme.palette.grey[200]
                                            : theme.palette.grey[700],
                                }}
                            />
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                        mb: 2,
                                    }}
                                >
                                    <Typography component="h2" variant="h3" color="text.primary">
                                        ${tier.price}
                                    </Typography>
                                    <Typography variant="h6" color="text.secondary">/mo</Typography>
                                </Box>
                                <ul>
                                    {tier.description.map((line) => (
                                        <Typography component="li" variant="subtitle1" key={line}>
                                            {line}
                                        </Typography>
                                    ))}
                                </ul>
                            </CardContent>
                            <CardActions>
                                {tier.buttonVariant === 'contained' ?
                                    <>
                                        <SignedOut>
                                            <SignInButton afterSignInUrl={'/office'} afterSignUpUrl={'/office'}
                                                          mode={'modal'}>
                                                <Button fullWidth variant={'contained'}>
                                                    {tier.buttonText}
                                                </Button>
                                            </SignInButton>
                                        </SignedOut>
                                        <SignedIn>
                                            <Button component={NavLink} to={'/office'} fullWidth variant={'contained'}>
                                                To App
                                            </Button>
                                        </SignedIn>
                                    </>
                                    :
                                    <Button fullWidth variant={'outlined'} disabled>
                                        {tier.buttonText}
                                    </Button>
                                }
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
        <Container
            maxWidth="md"
            component="footer"
            sx={{
                borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                mt: 8,
                py: [3, 6],
            }}
        >
            <Copyright/>
        </Container>
    </>
}

export default About
