import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {AppDispatch} from '../../store/store'
import {initialize} from '../../store/appSlice'

const Redirect = () => {
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(initialize())
    }, [])

    return <></>
}

export default Redirect
