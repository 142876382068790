import React, {useEffect} from 'react'
import {Route, Routes} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {DefaultLayout, OfficeLayout} from './components/layouts'
import {About, NotFound} from './components/static'
import {Dashboard, Invoices} from './components/office'
import {initialize} from './store/appSlice'
import {AppDispatch} from './store/store'

const App = () => {
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(initialize())
    }, [])

    return <Routes>
        <Route path='/' element={<DefaultLayout/>}>
            <Route path='/' element={<About/>}/>
            <Route path='*' element={<NotFound/>}/>
        </Route>
        <Route path='/office' element={<OfficeLayout/>}>
            <Route path='/office' element={<Dashboard/>}/>
            <Route path='/office/invoices' element={<Invoices/>}/>
            <Route path='*' element={<NotFound/>}/>
        </Route>
    </Routes>
}

export default App
