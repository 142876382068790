import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

interface PropsType {
    to: string
}

const Redirect = (props: PropsType) => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate(props.to)
    }, [])

    return <></>
}

export default Redirect
