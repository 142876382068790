import React from 'react'
import {Grid, Paper, Typography} from '@mui/material'

const Dashboard = () => {
    return <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                }}
            >
                <Typography component="h2" variant="h6" color="primary" gutterBottom>Dashboard</Typography>
            </Paper>
        </Grid>
    </Grid>
}

export default Dashboard
