import React from 'react'
import {NavLink} from 'react-router-dom'
import {Link, Typography} from '@mui/material'

const Copyright = () => {
    return <Typography variant="body2" color="text.secondary" align="center" sx={{mt: 5}}>
        {'Copyright © '}
        <Link component={NavLink} color="inherit" to={'/'}>
            Company name
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
    </Typography>
}

export default Copyright
